import React from 'react'
import { Document, Page, Text, View, StyleSheet, Image as PdfImage, Font } from '@react-pdf/renderer';
import { common } from '@mui/material/colors';
import {groupBy, formatDate, toBase64, getFile, compressImage, SurveyType} from '../../../_helpers';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import {getProjectLogoForReport} from "../../../apis/surveyingApis";
import { URLS } from '../../../apis/urls';
import { Buffer } from 'buffer';
import { numericFormatter } from 'react-number-format';

const { webApiUrl } = window.appConfig;

Font.register({
    family: 'Roboto',
    fonts: [
        { src: "/Content/fonts/Roboto-Regular.ttf", fontWeight: 400 },
        { src: "/Content/fonts/Roboto-Bold.ttf", fontWeight: "bold" },
        { src: "/Content/fonts/Roboto-Medium.ttf", fontWeight: 500 },
        { src: "/Content/fonts/Roboto-Light.ttf", fontWeight: 300 },
    ]
});

const reportCompletionKeys = [
    { label: "general.projectName", key: "projectName", value: "De Blauwe Schuit" },
    { label: "general.projectNumber", key: "projectNo", value: "0077" },
    { label: "survey.report.transfereeS", key: "responsible", value: "De heer W. Barendregt" },
    { label: "layout.navbar.address", key: "address", value: "Weena 18 3012 CM ROTTERDAM" },
    { label: "general.buildingNumber", key: "buildingNoExtern", value: "07" },
    { label: "survey.report.certificateNumber", key: "guaranteeCertNo", value: "" },
    { label: "general.date", key: "date", value: new Date() },
];

const meterKeys = [
    { key: 'meterReading_Electric1', label: 'survey.report.meterReadings.electricity1' },
    { key: 'meterReading_Electric2', label: 'survey.report.meterReadings.electricity2' },
    { key: 'meterReading_ElectricReturn1', label: 'survey.report.meterReadings.electricityReturn1' },
    { key: 'meterReading_ElectricReturn2', label: 'survey.report.meterReadings.electricityReturn2' },
    { key: 'meterReading_GasHeat', label: 'survey.report.meterReadings.gasHeat' },
    { key: 'meterReading_CityHeating', label: 'survey.report.meterReadings.cityHeating' },
    { key: 'meterReading_Water', label: 'survey.report.meterReadings.water' }
];

const styles = StyleSheet.create({
    document: {
        backgroundColor: common.white
    },
    topSection: {
        marginTop: 10,
        flexDirection: "row"
    },
    page: {
        display: "flex",
        paddingTop: 25,
        paddingBottom: 30,
        paddingLeft: 25,
        paddingRight: 25,
        flexDirection: "column",
    },
    reportCompletionSection: {
        padding: 10,
        flexDirection: "column",
        width: "50%"
    },
    meterSection: {
        flexDirection: "column",
    },
    reportCompletionDetail: {
        marginBottom: 10,
        flexDirection: "row"
    },
    reportCompletionDetailText: {
        fontSize: 10,
        flexGrow: 1,
        fontFamily: "Roboto",

    },
    reportCompletionDetailTextLabel: {
        fontFamily: "Roboto",
        width: 100,
        fontSize: 10,
    },
    reportCompletionDetailTextLabel2: {
        fontFamily: "Roboto",
        width: 108,
        fontSize: 10,
    },
    section: {
        padding: 10,
        flexDirection: 'row',
    },
    title: {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 15,
        lineHeight: 1.3
    },
    imageLabel: {
        fontFamily: "Roboto",
        fontSize: 12,
        fontWeight: "bold",
    },
    imageLabelsContainer: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        color: "#16215e",
        marginLeft: 10
    },
    divider: {
        width: "96%",
        height: 1,
        backgroundColor: common.black,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 10
    },
    jpds_image: {
        maxHeight: 116,
        width: 200,
    },
    signatureImage: {
        marginTop: 10,
        height: 70,
        width: 70
    },
    deliveryPointsSection: {
        marginBottom: 10,
        padding: 10,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    infoContainer: {
        padding: 10,
        paddingTop: 0,
        marginBottom: 15
    },
    dashedBorder: {
        borderBottom: "2px dashed grey",
        marginBottom: 4
    },
    footer: {
        position: "absolute",
        bottom: 10,
        left: 10,
        right: 10,
        margin: 10,
        borderTop: "1px solid black"
    },
    footerContent: {
        marginTop: 5,
        justifyContent: "space-between",
        flexDirection: "row",
    },
    footerPageLabel: {
        fontSize: 10,
        fontFamily: "Roboto",
    },
    infoRow: {
        flexDirection: 'row'
    },
    signatureContainer: {
        flexDirection: "row",
        padding: 10,
        justifyContent: "space-between",
    },
    signatureContent: {
        border: "1px solid red",
        width: "33%",
        flexGrow: 1,
    },
    attachmentsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    attachmentView: {
        background: 'red',
        width: 163,
        margin: "4px 4px"
    },
    repairRequests_image: {
        maxHeight: 100,
        objectFit: "contain",
        maxWidth: "100%",
        width: "auto"
    },
    pinPointsImages: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
});

const formatStringNewLine = (text) => text.replace(',', '\n');

const infoRow = (key, selectedInspection) => {
    switch (key) {
        case "projectName": {
            return selectedInspection[key].replace(`${selectedInspection.projectNo} - `, "");
        }
        case "date": {
            return formatDate(new Date(selectedInspection[key]));
        }
        case "address": {
            return formatStringNewLine(selectedInspection[key]);
        }
        case "responsible": {
            return (
                <View style={styles.infoRow}>
                    <Text>{selectedInspection.buyerRenter1}{"\n"}</Text>
                    <Text>{selectedInspection.buyerRenter2}</Text>
                </View>
            );
        }
        default: {
            return selectedInspection[key]
        }
    }
}

const getRepairRequestImage = (attachment, request) => async () => {
    const maxRetries = 10; // Maximum number of retries
    let retries = 0;
    while (retries < maxRetries) {
        try {
            if (attachment.attachmentId) {
                if (window.navigator.onLine) {
                    const url = `${webApiUrl}api/RepairRequest/GetImage/${request.requestId}/${attachment.attachmentId}`;
                    const response = await fetch(url);
                    if (!response.ok || response.status === 502) {
                        retries++;
                        console.log(`Error fetching or compressing image (attempt ${retries}):`);
                        continue;
                    }
                    if (response.headers.get('content-type') === "text/html") {  
                        return "/Content/Images/Background/report-img-placeholder.png"
                    }
                    try {
                        const blob = await response.blob();
                        const compressedImage = await compressImage(await toBase64(blob));
                        if (compressedImage) {
                            return compressedImage;
                        } else {
                            console.log("Compressed image not found");
                            return "/Content/Images/Background/report-img-placeholder.png"
                        }
                    }
                    catch (error) {
                        console.log("Error in compressing image", error);
                        return "/Content/Images/Background/report-img-placeholder.png"
                    }
                }
                return await compressImage(await getFile(attachment.attachmentId).then(data => data.file));
            }
        return await compressImage(await toBase64(attachment));
        } catch (error) {
            retries++;
            console.log(`Error fetching image or compressing image (attempt ${retries}):`, error);
        }
    }
    return "/Content/Images/Background/report-img-placeholder.png"
}

let drawingFiles = {}

const getDrawingFileURL = (dossierFileId, pageNumber = 1, compress= false) => async () => {
    let imageURL = "";
    const key = dossierFileId + '_' + pageNumber + '_' + compress;
    if (drawingFiles[key])
        return drawingFiles[key];
    if (window.navigator.onLine) {
        imageURL = URLS.GET_DOSSIER_PDF_IMAGE + dossierFileId + `?page=${pageNumber}`
    } else {
        imageURL = await getFile(dossierFileId).then(data => data.file);
    }
    if (compress === true) {
        imageURL = await compressImage(imageURL);
    }
    drawingFiles[key] = imageURL;
    return imageURL;
}

export const usePDFReport = (isSecondSignature, hideSignatureFromPDF, showMeldingImage) => {
    const { selectedInspection, repairRequests } = useSelector(state => state.surveying);
    const { selected } = useSelector(state => state.buildings);
    const groupedRequests = repairRequests && groupBy(repairRequests, "location");
    const groupedRequestsForMap = showMeldingImage && repairRequests && groupBy(
        repairRequests.filter(x => x.drawingPinLocation && x.drawingPinLocation.dossierFileId)
            .map(x => ({
                groupBy: x.drawingPinLocation.dossierFileId + '_' + x.drawingPinLocation.pageNumber,
                ...x
            })), "groupBy");
        
    const { t } = useTranslation();
    const logo = selectedInspection ? () => Promise.resolve(getProjectLogoForReport(selectedInspection.projectId)
        .then(data => {
            if (data.data && data.data.byteLength > 0) {
                return "data:" + data.type + ";base64, " + Buffer.from(data.data, 'base64').toString('base64');
            }
            return data.data;
        })) : null;

    const title = isSecondSignature ? t("survey.report.recoveryReadyReport") : selectedInspection?.surveyType === SurveyType.PreDelivery ? t("survey.report.predeliveryPoints") :selectedInspection?.surveyType === SurveyType.Inspection   ?  t("survey.report.inspectionPoints")  : t("survey.report.officialReportOfCompletion");
        
    return (
        <Document
            author="Huisinfo"
            keywords={title}
            subject={title}
            title={title}
        >
            {selectedInspection &&
                <Page size="A4" style={styles.page}>
                    <View style={styles.footer} render={({ pageNumber, totalPages }) => (
                        <View style={styles.footerContent}>
                            <Text style={styles.reportCompletionDetailText}>{formatDate(new Date(selectedInspection.date))}</Text>
                            <Text style={styles.footerPageLabel}>{t("survey.report.pagination.label", {pageNumber : pageNumber, totalPages: totalPages})}</Text>
                        </View>
                    )} fixed />

                    <View style={styles.topSection} fixed>
                        <View style={[styles.reportCompletionSection, { flexGrow: 1 }]}>
                            <Text style={styles.title}>{title}</Text>
                            <View>
                                {
                                    reportCompletionKeys.map(p => {
                                        return (
                                            <View key={p.key} style={styles.reportCompletionDetail}>
                                                <Text style={styles.reportCompletionDetailTextLabel}>
                                                    {t(p.label)} :
                                                </Text>
                                                <Text style={styles.reportCompletionDetailText}>
                                                    {infoRow(p.key, selectedInspection)}
                                                </Text>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                        {logo && <View style={styles.section}>
                            <PdfImage style={styles.jpds_image} src={logo}  cache={true} />
                        </View>}
                    </View>

                    <View style={styles.divider} fixed />

                    {!hideSignatureFromPDF && <View style={[styles.topSection, { marginTop: 0 }]}>
                        <View style={styles.reportCompletionSection}>
                            <Text style={styles.title}>{t("survey.meterReadingsElectricity")}</Text>
                            <View>
                                {meterKeys.slice(0, 4).map(({ key, label }) => {
                                    return (
                                        <View key={key} style={styles.reportCompletionDetail}>
                                            <Text style={styles.reportCompletionDetailTextLabel}>
                                                {`${t(label)} : `}
                                            </Text>
                                            <Text style={styles.reportCompletionDetailText}>
                                                {selectedInspection[key] ? selectedInspection[key] : 0}
                                            </Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                        <View style={styles.reportCompletionSection}>
                            <Text style={styles.title}>{t("survey.meterReadingsOther")}</Text>
                            <View>
                                {meterKeys.slice(4).map(({ key, label }) => {
                                    if(key=="meterReading_CityHeating" && !selectedInspection.cityHeating) return;
                                    return (
                                        <View key={key} style={styles.reportCompletionDetail}>
                                            <Text style={styles.reportCompletionDetailTextLabel2}>
                                                {`${t(label)} : `}
                                            </Text>
                                            <Text style={styles.reportCompletionDetailText}>
                                                {key == "meterReading_CityHeating" ? selectedInspection?.[key] && numericFormatter(selectedInspection[key].toString(),
                                                    {
                                                        decimalScale: 3,
                                                        fixedDecimalScale: true,
                                                        thousandSeparator: ".",
                                                        decimalSeparator: ","
                                                    }
                                                )
                                                    : (selectedInspection[key] ? selectedInspection[key] : 0)}
                                            </Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    </View>}


                    {!hideSignatureFromPDF && <View style={styles.deliveryPointsSection}>
                        <Text style={[styles.reportCompletionDetailText]}>
                            {selectedInspection.reportHeader}
                        </Text>
                    </View>}

                    <View>
                        <View style={[styles.deliveryPointsSection, { marginBottom: 0 }]}>
                            <Text style={[styles.title, { marginBottom: 0 }]}>{ selectedInspection.surveyType === SurveyType.PreDelivery ? t("survey.report.predeliveryPoints") :selectedInspection.surveyType === SurveyType.Inspection   ?  t("survey.report.inspectionPoints"):  t("survey.deliveryPoints")}</Text>
                            <Text style={[styles.title, { marginBottom: 0 }]}>{t("general.number")}</Text>
                        </View>

                        {groupedRequests && !!Object.keys(groupedRequests).length && Object.keys(groupedRequests).map(key => (
                            <View key={key} style={styles.infoContainer}>
                                <Text style={[styles.title, { borderBottom: "2px dashed grey", marginBottom: 4 }]}>
                                    {key}
                                </Text>
                                {
                                    groupedRequests[key].map(request => {
                                        const dossierFileId = request.drawingPinLocation && request.drawingPinLocation.dossierFileId;
                                        const pageNumber = dossierFileId && request.drawingPinLocation.pageNumber;
                                        const xLoc = dossierFileId && request.drawingPinLocation.xrel * 100;
                                        const yLoc = dossierFileId && request.drawingPinLocation.yrel * 100;
                                        const imageURL = dossierFileId && getDrawingFileURL(dossierFileId, pageNumber, true);

                                        return (
                                            <View wrap={false} key={request.requestId}>
                                                <View style={[styles.deliveryPointsSection, { marginBottom: 0, padding: 0 }]}>
                                                    <Text style={[styles.reportCompletionDetailText]}>
                                                        {!request.sync && request.detailDesc ? request.detailDesc : request.desc}
                                                    </Text>
                                                    <View style={{ alignItems: "flex-end" }}>
                                                        <Text style={[styles.title, { marginBottom: 0 }]}>
                                                            {request.number ? request.number : t("general.draft")}
                                                        </Text>
                                                        {request.sync !== 0 && !!request.numberOffline && <Text style={[styles.title, { marginBottom: 0, color: "grey" }]}>
                                                            {request.numberOffline}
                                                        </Text>}
                                                    </View>
                                                </View>
                                                {request.sync === 0 && <Text style={[styles.reportCompletionDetailText]}>
                                                    {request.detailedDesc}
                                                </Text>}
                                                <View style={styles.attachmentsContainer}>
                                                    {
                                                        showMeldingImage && request.attachments && !!request.attachments.length && request.attachments.filter((attachment)=>attachment?.type?.toLowerCase() !== "application/pdf").slice(0, 2).map(attachment => (
                                                            <View key={attachment.attachmentId} style={styles.attachmentView}>
                                                                <PdfImage
                                                                    cache={true}
                                                                    alt="Logo"
                                                                    style={styles.repairRequests_image}
                                                                    src={getRepairRequestImage(attachment, request)}
                                                                />
                                                            </View>
                                                        ))
                                                    }
                                                    <View style={{ flexGrow: 1 }} />
                                                    {
                                                        showMeldingImage && dossierFileId &&
                                                        <View style={{ display: 'flex', flexDirection: 'row', ...styles.attachmentView }}>
                                                            <View style={{ position: 'relative' }}>
                                                                <PdfImage style={styles.repairRequests_image} src={imageURL} />
                                                                <PdfImage style={{ width: 6, height: 6, position: 'absolute', top: yLoc + '%', left: xLoc + '%', marginTop: -6, marginLeft: -3 }} src={"/Content/icon/pin.png"} />
                                                            </View>
                                                        </View>
                                                    }
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        ))}

                    </View>

                    {selectedInspection.executorComments !== "" &&
                        <>
                            <View style={[styles.deliveryPointsSection, { flexDirection: 'column' }]}>
                                <Text style={styles.reportCompletionDetailText}>{t('survey.report.executorComments')}</Text>
                                <Text style={[styles.reportCompletionDetailText, { marginBottom: 5 }]}>
                                    {selectedInspection.executorComments}
                                </Text>
                            </View></>}
                    {selectedInspection.buyerRenterComments !== "" &&
                        <>
                            <View style={[styles.deliveryPointsSection, { flexDirection: 'column' }]}>
                                <Text style={styles.reportCompletionDetailText}>{t('survey.report.buyerRenterComments')}</Text>
                                <Text style={[styles.reportCompletionDetailText, { marginBottom: 5 }]}>
                                    {selectedInspection.buyerRenterComments}
                                </Text>
                            </View>
                        </>
                    }
                    {!hideSignatureFromPDF && <View style={[styles.deliveryPointsSection, { flexDirection: 'column' }]}>
                        <Text style={[styles.reportCompletionDetailText, { marginBottom: 5 }]}>
                            {isSecondSignature ? t("survey.report.deliveryPointsAgreement") : selectedInspection.reportFooter}
                        </Text>
                    </View>}

                    {!hideSignatureFromPDF && <View style={styles.signatureContainer}>
                        {!isSecondSignature && selectedInspection.executorSignature &&
                            <View styles={styles.signatureContent}>
                                <Text style={styles.reportCompletionDetailText}>{selectedInspection.executorSignature.name || selectedInspection.executedBy}</Text>
                                <PdfImage style={styles.signatureImage} src={`${"data:image/png;base64," + selectedInspection.executorSignature.content}`} />
                            </View>
                        }

                        {selectedInspection[`buyerRenter1${isSecondSignature ? "Second" : ""}Signature`] &&
                            <View styles={styles.signatureContent}>
                                <Text style={styles.reportCompletionDetailText}>
                                    {selectedInspection.buyerRenter1}
                                </Text>
                                <PdfImage
                                    style={styles.signatureImage}
                                    src={`${"data:image/png;base64," + selectedInspection[`buyerRenter1${isSecondSignature ? "Second" : ""}Signature`].content}`} />
                            </View>
                        }

                        {selectedInspection[`buyerRenter2${isSecondSignature ? "Second" : ""}Signature`] &&
                            <View styles={styles.signatureContent}>
                                <Text style={styles.reportCompletionDetailText}>
                                    {selectedInspection.buyerRenter2}
                                </Text>
                                <PdfImage
                                    style={styles.signatureImage}
                                    src={`${"data:image/png;base64," + selectedInspection[`buyerRenter2${isSecondSignature ? "Second" : ""}Signature`].content}`} />
                            </View>
                        }
                    </View>}

                </Page>
            }
            {
                showMeldingImage && selectedInspection && (groupedRequestsForMap && !!Object.keys(groupedRequestsForMap).length) &&
                <Page size="A4" style={[styles.page, styles.pinPointsImages]}>
                    <View style={styles.footer} render={({ pageNumber, totalPages }) => (
                        <View style={styles.footerContent}>
                            <Text style={styles.reportCompletionDetailText}>{formatDate(new Date(selectedInspection.date))}</Text>
                            <Text style={styles.footerPageLabel}>{t("survey.report.pagination.label", { pageNumber: pageNumber, totalPages: totalPages })}</Text>
                        </View>
                    )} fixed />
                    {
                        Object.keys(groupedRequestsForMap).map((key, i) => {
                            const splitValues = key.split('_');
                            const imageURL = getDrawingFileURL(splitValues[0], splitValues[1]);

                            return (
                                <View key={key} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <View style={{ position: 'relative' }}>
                                        <PdfImage style={{ height: "auto", maxWidth: "100%", objectFit: "contain" }} break={!!i} src={imageURL} />
                                        {
                                            groupedRequestsForMap[key].map((x, i) => {
                                                const xLoc = x.drawingPinLocation.xrel * 100;
                                                const yLoc = x.drawingPinLocation.yrel * 100;
                                                return (
                                                    <View key={key + i} style={{ position: 'absolute', top: yLoc + '%', left: xLoc + '%', marginTop: -18, marginLeft: -5, width: 10, textAlign: 'center' }}>
                                                        <Text style={{ color: 'red', fontSize: '8px', fontWeight: 'bold', lineHeight: 1, fontFamily: "Roboto", width: '100px', marginLeft: -45 }}>{x.number || x.numberOffline}</Text>
                                                        <PdfImage style={{ width: 10, height: 10 }} src={"/Content/icon/pin.png"} />
                                                    </View>
                                                )
                                            })
                                        }
                                    </View>
                                </View>
                            )
                        })
                    }
                </Page>
            }
        </Document>
    );
};
