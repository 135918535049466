import React, {memo} from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { URLS } from '../../../apis/urls';
import { getMimeTypefromString } from '../../../_helpers';
import { getBrowserInfo } from '../../../components/DataGridV2/utils';

const { iOSSafari } = getBrowserInfo()

function DocumentViewer({contractId}) {
    const classes = useStyles();

  const docs = [{
    uri: URLS.GET_CONTRACT_PREVIEW + contractId,
    fileType: getMimeTypefromString(".pdf"),
    name: ""
  }] ;
  return (
    <Box mx={2}  height="calc(100% - 56px)">
      <DocViewer
      config={{
        header: {
          disableHeader: true,
          disableFileName: true
        },
        pdfVerticalScrollByDefault: true
      }}
      documents={[docs]}
      activeDocument={docs[0]}
      pluginRenderers={DocViewerRenderers}
      className={classes.documentComponent}
    />
    </Box> 
  )
}

export default memo(DocumentViewer)


const useStyles = makeStyles((theme) => ({
  documentComponent: {
      '& div:nth-last-child(1)': {
          '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.grey[400]
          },
      },
      '& #pdf-controls': {
        background: theme.palette.common.white
      },
      '& #pdf-download': {
        display:  iOSSafari ? "none" : "flex"
    }
  },
}));