import React, { useEffect, useMemo, useState } from "react";
import {
    IconButton,
    Dialog,
    DialogTitle,
    Slide,
    DialogContent,
    Typography,
    Grid,
    CircularProgress,
    Container,
    useMediaQuery,
    Divider,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Close, SkipPrevious, SkipNext, ZoomIn, ZoomOut, CloudDownload } from "@mui/icons-material";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useTheme } from "@mui/material";
import { fileUploadConfig } from "../_helpers/fileUploadConfig";
import clsx from 'clsx';
import SVGFileRenderer from "./SVGFileRenderer";
import { getBrowserInfo } from "./DataGridV2/utils";

const { iOSSafari } = getBrowserInfo()

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Viewer = React.memo(({ myHeader, document, classes, ...props }) => {
    return <DocViewer
        config={{
            header: {
                overrideComponent: myHeader,
            },
            pdfVerticalScrollByDefault: true
        }}
        documents={[document]}
        className={classes.documentComponent}
        pluginRenderers={[SVGFileRenderer, ...DocViewerRenderers]}
        style={{ width: '100%' }} />
}, (p, n) => {
    return p.classes === n.classes && p.document === n.document && p.selectedFile === n.selectedFile;
});

function DocumentViewer(props) {
    const { open, onClose, documents, loader, isSurveying, radius, selectedFile, ...rest } = props;
    const theme = useTheme();
    const matchWidthDownXs = useMediaQuery(theme.breakpoints.down('sm'));
    const [zoom, setZoom] = useState(1.5);
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const classes = useStyles({ isSurveying, matchWidthDownXs, zoom });

    const handleCloseObjects = () => {
        onClose();
    };

    useEffect(() => {
        setSelectedFileIndex(selectedFile || 0);
        setZoom(1);
    }, [selectedFile, open])

    const downloadFileHandler = async (data, download) => {
        // console.log(download)
        let link = document.createElement("a");
        link.href = data;
        link.download = download;
        document.body.appendChild(link).click();
    }

    const handleZoom = (type) => {
        if (type === "in") setZoom(p => p + 0.1);
        else if (type === "out") setZoom(p => p - 0.1);
    }

    const nextDocument = () => {
        setZoom(1);
        setSelectedFileIndex(selectedFileIndex + 1);
    }

    const prevDocument = () => {
        setZoom(1);
        setSelectedFileIndex(selectedFileIndex - 1);
    }


    const myHeader = (state, previousDocument) => {
        if (!state.currentDocument || state.config.header.disableFileName) {
            return null;
        }

        //useEffect(() => {
            if (open && state.currentDocument.fileData) {
                setTimeout(() => {
                    const downloadPDFElement = document.getElementById("pdf-download");
                    const pdfPageToggler = document.getElementById("pdf-toggle-pagination");
                    if (pdfPageToggler) {
                        pdfPageToggler.onfocus = () => {
                            if (document.createEvent) pdfPageToggler.dispatchEvent(new MouseEvent('mousedown'));
                        };
                        pdfPageToggler.onload = async () => {
                            pdfPageToggler.focus();
                        }
                    }
                    if (downloadPDFElement) {
                        downloadPDFElement.setAttribute("href", state.currentDocument.uri);
                        downloadPDFElement.setAttribute("download", state.currentDocument.name ? state.currentDocument.name : "");
                    }
                }, [500])
            }
        //}, [open, state.currentDocument.fileData])

        const file = fileUploadConfig.allowedMimeTypes.find(p => p.mime === state.currentDocument.fileType) || {};
        return <>
            {(isSurveying && matchWidthDownXs) && <DialogTitle className={classes.dialogTitle} id="dialog-objects-title">
                <Grid className={clsx(classes.dialogTitleContent, classes.dialogTitleContainerWrap)} container spacing={1}>
                    <Grid item className={clsx(classes.grow, classes.dialogTitleContentWrap)}>
                        <Typography variant={'h6'} noWrap>{state.currentDocument.name || ""}</Typography>
                        {/*<Typography variant="h5" className={classes.dialogTitleTypo}>{t('Selecteer betrokkene')}</Typography>*/}
                    </Grid>
                    <Grid>
                        <IconButton
                            className={classes.closeButton}
                            // color="primary"
                            aria-label="close"
                            onClick={handleCloseObjects}
                            size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
            </DialogTitle>}
            <Container className={classes.headerContainer}>
                <Typography variant={'h6'} noWrap>{(!isSurveying || !matchWidthDownXs) && (state.currentDocument.name || "")}</Typography>
                <div className={classes.iconContainer}>
                    {fileUploadConfig.allowedImageFormats.some((p) => p.extension === file.extension) && <>
                        <IconButton
                            onClick={
                                () => downloadFileHandler(state.currentDocument.uri, state.currentDocument.name + file.extension)
                            }
                            size="large">
                            <CloudDownload />
                        </IconButton>
                        <IconButton
                            className={classes.zoomInButton}
                            disabled={+zoom.toFixed(2) === 5}
                            onClick={() => handleZoom('in')}
                            size="large">
                            <ZoomIn />
                        </IconButton>
                        <IconButton
                            className={classes.zoomOutButton}
                            disabled={+zoom.toFixed(1) === 0.1}
                            onClick={() => handleZoom('out')}
                            size="large">
                            <ZoomOut />
                        </IconButton>
                    </>}
                    <IconButton onClick={prevDocument} disabled={selectedFileIndex === 0} size="large">
                        <SkipPrevious />
                    </IconButton>
                    <IconButton
                        onClick={nextDocument}
                        disabled={selectedFileIndex === (documents.length - 1)}
                        size="large">
                        <SkipNext />
                    </IconButton>
                </div>
            </Container>
        </>;
    };
    const updatedDocuments = useMemo(()=>{
        return documents?.map((doc)=>({...doc , uri:doc?.uri?.startsWith("/") ? `${window.location.origin}${doc?.uri}` : doc?.uri}))
    },[documents])
    return (
        <Dialog className={clsx(radius, classes.docContainer)} fullScreen={matchWidthDownXs} TransitionComponent={Transition}
            fullWidth={true} maxWidth='lg' open={open} onClose={handleCloseObjects} aria-labelledby="form-dialog-title" scroll="paper">
            {(!isSurveying || !matchWidthDownXs) && <DialogTitle className={classes.dialogTitle} id="dialog-objects-title">
                <Grid className={classes.dialogTitleContent} container spacing={1}>
                    <Grid item className={classes.grow}>
                        {/*<Typography variant="h5" className={classes.dialogTitleTypo}>{t('Selecteer betrokkene')}</Typography>*/}
                    </Grid>
                    <Grid>
                        <IconButton
                            className={classes.closeButton}
                            // color="inherit"
                            aria-label="close"
                            onClick={handleCloseObjects}
                            size="large">
                            <Close className={classes.iconStyle} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>}
            <DialogContent className={classes.dialogContent}>
                {loader && <CircularProgress className={classes.mAuto} />}
                {!loader && open && <Grid container className={classes.dialogContainer} spacing={1} justifyContent="center" align={"center"}>
                    <Viewer selectedFile={selectedFile} classes={classes} myHeader={myHeader} document={updatedDocuments[selectedFileIndex]} />
                </Grid>
                }
            </DialogContent>
        </Dialog>
    );
}

export default React.memo(DocumentViewer, (p, n) => {
    return p.open === n.open
        && p.documents === n.documents
        && p.loader === n.loader
        && p.isSurveying === n.isSurveying
        && p.radius === n.radius
        && p.selectedFile === n.selectedFile
})


const useStyles = makeStyles((theme) => ({
    documentComponent: {
        '& div:nth-last-child(1)': {
            '& > div > img': {
                transform: ({ zoom }) => {
                    const zooming = +zoom.toFixed(2)
                    return `scale(${zooming <= 0.1 ? 0.1 : zooming >= 5.9 ? 5.9 : zooming})`
                },

                transition: '1s all',
                transformOrigin: `0 0`,
            },
            '& #image-renderer': {
                overflow: 'auto'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[400]
            },
        },
        '& #pdf-controls': {
            background: theme.palette.grey[100]
        },
        '& #pdf-download': {
            display:  iOSSafari ? "none" : "flex",
        }
    },
    docContainer: {
        marginTop: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) ? '18%' : 0,
        '& .MuiPaper-rounded': {
            borderTopLeftRadius: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && 15,
            borderTopRightRadius: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && 15,
        },
        '& .MuiDialogTitle-root': {
            background: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && theme.palette.common.white
        },
    },
    grow: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center'
    },
    border: {
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        marginTop: 20,
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: "sticky",
        top: 0
    },
    iconContainer: {
        display: 'flex',
        flexWrap: 'no-wrap'
    },
    dialogContent: {
        // padding: theme.spacing(1.25),
        paddingTop: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && "10px !important",
        minHeight: '80vh',
        display: 'flex'
    },
    mAuto: {
        margin: 'auto'
    },
    dialogContainer: {
        overflow: "auto",
        '& > div': {
            position: 'relative',
            height: '100%',
        }
    },
    dialogTitle: {
        padding: theme.spacing(.625),
        background: theme.palette.primary.main,
        '& .MuiIconButton-label > .MuiSvgIcon-root': {
            fill: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) && theme.palette.grey[300]
        },
    },
    dialogTitleTypo: {
        color: 'white',
        [theme.breakpoints.down('md')]: {
            fontSize: '5vw',
        },
    },
    dialogTitleContent: {
        padding: ({ isSurveying, matchWidthDownXs }) => (isSurveying && matchWidthDownXs) ? theme.spacing(0) : theme.spacing(1.25),
    },
    dialogTitleContentWrap: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dialogTitleContainerWrap: {
        flexWrap: 'inherit'
    },
    iconStyle: {
        fill: 'white',
        width: '1em',
        height: '1em',
    },
    closeButton: {
        margin: 0,
    },
    userNameTypo: {
        paddingLeft: theme.spacing(1.25),
        margin: 5,
        [theme.breakpoints.down('md')]: {
            fontSize: '3vw',
        },
    },
    tableTitles: {
        display: 'flex',
        // alignItems: 'center',
    },
    gridTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column'
    },
    tableTitlesTypo: {
        // color: 'white',
        alignSelf: 'center',
        paddingLeft: theme.spacing(.625),
        [theme.breakpoints.down('md')]: {
            fontSize: '4vw',
        },
    },
    borderBottom: {
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        background: theme.palette.grey[200]
    },
    subtitles: {
        fontSize: '0.696rem',
        marginTop: 10,
        textAlign: 'center',
        marginBottom: -2,
    },
    subtile2: {
        fontSize: '0.686rem',
        textAlign: 'center'
        // color: '#ffffff'
    },
    userContainer: {
        marginTop: 13,
    },
    divider: {

    },
    zoomInButton: {
        pointerEvents: ({ zoom }) => +zoom.toFixed(2) >= 5.9 ? "none" : "auto"
    },
    zoomOutButton: {
        pointerEvents: ({ zoom }) => +zoom.toFixed(2) <= 0.1 ? "none" : "auto"
    },
}));